<template>
  <Loader v-if="loading" />
  <div class="container">
    <!-- <div style="text-align: right;">
            <font-awesome-icon icon="right-from-bracket" @click="logout()" style="cursor:pointer !important"/>
          </div> -->
    <div v-if="survey_submitted == 1">
      <div class="p-buttons-wrap">
            <button type="button" class="btn btn-warning" @click="edit" style="color:white;box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px; background: linear-gradient(90deg, rgba(131, 58, 180, 1) 0%, rgba(69, 70, 252, 1) 100%)">Edit Details</button>
      </div>
      
    </div>
    <div style="margin-top: 10px"><SurveyComponent :model="survey" /></div>
   
      
    

  </div>
</template>
<script>
import Loader from "../components/Loader.vue";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import data from "@/data";
import axios from "axios";
import apis from "@/apis";
import Swal from 'sweetalert2';
import { themeJson } from "./theme";
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  }
});
export default {
  name: 'SURVEY',
  components: {
    Loader
  },
  data() {
    return {
      loading: false,
      login: true,
      survey: new Model({}),
      result: null,
      surveytemplate: {},
      sgb_board_id: "",
      employee_code: "",
      survey_id: "",
      temp_surveyData: '',
      username: '',
      password: '',
      jwtToken: '',
      survey_submitted: 0,
      family_details: '',
      survey_response: '',
    }
  },
  methods: {
    edit() {
      this.survey.mode = "edit";
    },
    logout() {
      localStorage.clear();
      this.$router.push('/');
    },
    async Log_in() {
      //Login api start
      var username = this.$route.params.username;
      let details = {
        "username": username
        //"password": "1234"
      }
      this.loading = true;
      var promise = apis.login(details);
      promise.then((response) => {
        this.loading = false;
        var login_result = response.data;
        var status_result = response.data["status_code"];
        if (status_result == 200) {
          if (login_result['survey_data'] && login_result['survey_data']['survey_id']) {
            this.sgb_board_id = login_result["sgb_board_id"];
            this.employee_code = login_result['user_code'];
            this.jwtToken = login_result['jwtToken'];
            this.survey_submitted = login_result['survey_submitted'];
            this.survey_response = login_result['survey_response'];
            this.family_details = login_result['family_details'];
            this.survey_id = login_result['survey_data']['survey_id'];
            var template = login_result['survey_data']['survey_template'];
            this.surveytemplate = JSON.parse(template);
            if (this.survey_submitted==1) {
              this.surveytemplate['pageNextText']='Next';
            }
            this.survey = new Model(this.surveytemplate);
            if (this.survey_response && this.survey_response != '') {
              this.survey.data = JSON.parse(this.survey_response);
            }
            this.survey.applyTheme(themeJson);
            this.survey.onComplete.add((survey) => {
              this.result = JSON.stringify(survey.data);
              let jwtToken = this.jwtToken;
              let details = {
                "sgb_board_id": this.sgb_board_id,
                "emp_code": this.employee_code,
                "survey_id": this.survey_id,
                "survey_response": this.result,
                "type": 'submit'
              }
              var header = {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwtToken}`
                }
              };
              this.loading = true;
              var promise = apis.save_guard_survey(details, header);
              promise.then((response) => {
                this.loading = false;
                if (response.data.status == true) {
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  // this.$router.push('/');
                }
                else {
                  Toast.fire({
                    icon: 'error',
                    title: response.data.message
                  });
                }
              });
            });
            this.survey.onPartialSend.add((survey) => {
              this.result = JSON.stringify(survey.data);
              let jwtToken = this.jwtToken;
              let details = {
                "sgb_board_id": this.sgb_board_id,
                "emp_code": this.employee_code,
                "survey_id": this.survey_id,
                "survey_response": this.result,
                "type": 'save'
              }
              var header = {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwtToken}`
                }
              };
              this.loading = true;
              var promise = apis.save_guard_survey(details, header);
              promise.then((response) => {
                this.loading = false;
              });
            });
            this.survey.onValueChanged.add((sender, options) => {
              if (options.name === "Marital_status") {
                const maritalStatus = options.value;
                this.survey.data = { Marital_status: maritalStatus };
              }
              if (options.name === "ChildrenDetails") {
                var surveydata = this.survey.data;
                if (this.temp_surveyData && surveydata.ChildrenDetails) {
                  for (let index = 0; index < surveydata.ChildrenDetails.length; index++) {
                    if (
                      this.temp_surveyData.ChildrenDetails[index] &&
                      this.temp_surveyData.ChildrenDetails[index]['Children_Qualification_Status'] &&
                      surveydata.ChildrenDetails[index]['Children_Qualification_Status'] &&
                      surveydata.ChildrenDetails[index]['Children_Qualification_Status'] !==
                      this.temp_surveyData.ChildrenDetails[index]['Children_Qualification_Status']
                    ) {
                      // Clear the specified fields for the specific panel index
                      delete surveydata.ChildrenDetails[index].Childrenstd;
                      delete surveydata.ChildrenDetails[index].Childrensubject;
                      delete surveydata.ChildrenDetails[index].ChildrenTotalQualificationYear;
                      delete surveydata.ChildrenDetails[index].ChildrenAdminssionDate;
                      delete surveydata.ChildrenDetails[index].ChildrenCompletedstd;
                      delete surveydata.ChildrenDetails[index].ChildrenQualificationCompletedYear;
                    }
                  }
                }
                this.survey.data = surveydata;
                this.temp_surveyData = JSON.parse(JSON.stringify(surveydata));
              }
            });
            // this.survey.onAfterRenderSurvey.add(function (survey, options) {
            // });
            if (this.survey_submitted==1) {
              this.survey.nextButtonText = 'Next';
              this.survey.mode = "display";
            }
          }
          else {
            Toast.fire({
              icon: 'warning',
              title: 'record Not Found'
            });
          }
        }
        else {
          Toast.fire({
            icon: 'error',
            title: response.data.message
          });
        }
      })
        .catch(error => {
          this.loading = false;
          console.error('API Error:', error);
        });
      //Login api end
    }
  },
  async mounted() {
    // this.sgb_board_id = this.$route.params.sgb_board_id;
    // this.employee_code = this.$route.params.employee_code;
    // this.survey_id = this.$route.params.survey_id;
    // await this.get_template();
    // let surveyJSON = {};
    // surveyJSON = data.Template;  
    // this.survey = new Model(surveyJSON);
    // if (localStorage && localStorage.getItem('sgb_board_id')) {
    //   this.sgb_board_id = localStorage.getItem('sgb_board_id');
    //   this.employee_code = localStorage.getItem('employee_code');
    //   this.jwtToken = localStorage.getItem('jwtToken');
    //   this.survey_id = localStorage.getItem('survey_id');
    //   var surveytemplate = localStorage.getItem('surveytemplate');
    //   this.surveytemplate = JSON.parse(surveytemplate);
    //   localStorage.clear();
    // }
    // else{this.$router.push('/');
    // }
    await this.Log_in();
  }
}
</script>
<style>
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 91vh;
}

.container {
  flex: 1;
}

.btn-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.info {
  background-color: #005487;
  width: 130px;
  height: 40px;
  border-radius: 5px;
  border: 0;
  color: #fff;
  font-size: 15px;
  margin-left: 10px;
  cursor: pointer;
}

.info2 {
  background-color: #05a32d;
  width: 130px;
  height: 40px;
  border-radius: 5px;
  border: 0;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}

.card:last-child {
  margin-top: 5%;
}

.error {
  color: red;
}
</style>
