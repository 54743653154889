<template>
    <div class="my-preview-container" v-if="show">
      <div class="files-container" >
        
        <div  class="button-container" v-for="fileItem in question.value" :key="fileItem.name">
            <div class="image-container"> <img :src=fileItem.content alt="" class="responsive-image">
              <div class="btn btn--remove" @click="removeFile(fileItem.name)" title="Remove file">
            X
          </div>
            </div>
           
           
          <!-- <div class="btn btn--choose" @click="downloadFile(fileItem)">
            Download {{ fileItem.name }}
          </div>-->
           
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { QuestionFileModel } from "survey-core";
  
  export default {
    name: 'SgbSurveyUiFilePreviewComponent',
  
    props: {
      question: {
        type: Object,
        required: true
      },
    },
  
    data() {
      return {
        show:true
      };
    },
  
    mounted() {
      // Any initialization logic can go here
    },
  
    methods: {
         
      downloadFile(fileItem) {
        fetch(fileItem.content)
          .then((response) => response.blob())
          .then((blob) => {
            const file = new File([blob], fileItem.name, {
              type: fileItem.type,
            });
            const url = URL.createObjectURL(file);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileItem.name;
            a.click();
            URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      },
  
       removeFile(fileName) {
        // this.question.removeFile(fileName);
         this.question.value = [];
         this.show=false;
       }
    },
  };
  </script>
  
  <style>
  .my-preview-container {
    margin-top: 2em;
    max-width: calc(100% - 64px);
    margin-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
  
  .btn {
    appearance: none;
    border: 1px solid lightgray;
    cursor: pointer;
    padding: 4px 8px;
    color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .button-container {
    width: 100%;
    display: flex;
    gap: 8px;
  }
  
  .btn--choose {
    background-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
    overflow: hidden;
    flex: 1 1 auto;
  }
  
  .btn--choose:hover {
    background-color: var(--sjs-primary-backcolor-dark, rgb(20, 164, 139));
  }
  
  .btn--remove {
    background-color: var(--sjs-special-red, var(--red, #e60a3e));
  }
  
  .btn--remove:hover {
    background-color: rgb(217, 4, 49);
  }

  .image-container {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center;    /* Vertically center (if the container has a height) */
  height: 100%;           /* Set a height if needed */
}
  .responsive-image {
  width: 100%;
  height: auto;
  max-width: 200px !important; /* Maximum width on larger screens */
  max-height: 250px !important; /* Maximum height on larger screens */
}
  </style>
  