<template>
  <div>
    <Loader v-if="loading" />
    <div class="container">
      <div v-if="reject_remark!=''" style="padding-top:3%; margin-bottom:-20px">
        <!-- <p><b>तुमचा आधार अपडेट विनंती तपशील नाकारण्यात आला आहे. </b></p> -->
        <p><b>नकाराचे कारण : </b>{{ reject_remark }}</p>
      </div>
      <div v-if="survey_submitted == 1">
        <!-- <div class="p-buttons-wrap">
          <button
            type="button"
            class="btn btn-warning"
            @click="edit"
            style="
              color: white;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
                rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
              background: linear-gradient(
                90deg,
                rgba(131, 58, 180, 1) 0%,
                rgba(69, 70, 252, 1) 100%
              );
            "
          >
            Edit Details
          </button>
        </div> -->
      </div>
      <div class="card" style="padding: 10px;  border:none">
        <div v-if="show_survey==false">
          <h1>{{show_msg}}</h1>
        </div>
      </div>
      <div v-if="show_survey==true" style="margin-top: 10px"><SurveyComponent :model="survey" /></div>
    </div>
  </div>
</template>
<script>
import Loader from "../components/Loader.vue";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import data from "@/data";
import axios from "axios";
import apis from "@/apis";
import lambda from "@/apis/lambda";
import Swal from "sweetalert2";
import { themeJson } from "./theme";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});
export default {
  name: "SURVEY",
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
      login: true,
      survey: new Model({}),
      result: null,
      surveytemplate: {},
      sgb_board_id: "",
      employee_code: "",
      survey_id: "",
      temp_surveyData: "",
      username: "",
      password: "",
      jwtToken: "",
      survey_submitted: 0,
      survey_response: "",
      show_survey:true,
      show_msg:"",
      reject_remark:"",
      aapproval_status:"",
    };
  },
  methods: {
    edit() {
      this.survey.mode = "edit";
    },
    async Log_in() {
      //Login api start
      var username = this.$route.params.username;
      let details = {
        username: username,
        //"password": "1234"
      };
      this.loading = true;
      var promise = apis.uat_login(details);
      promise
        .then((response) => {
          this.loading = false;
          var login_result = response.data;
          var status_result = response.data["status_code"];
          if (status_result == 200) {
            if (
              login_result["survey_data"] &&
              login_result["survey_data"]["survey_id"]
            ) {
              this.sgb_board_id = login_result["sgb_board_id"];
              this.employee_code = login_result["user_code"];
              this.jwtToken = login_result["jwtToken"];
              this.survey_submitted = login_result["survey_submitted"];
              this.survey_response = login_result["survey_response"];
              this.survey_id = login_result["survey_data"]["survey_id"];
              var template = login_result["survey_data"]["survey_template"];
              this.surveytemplate = JSON.parse(template);

              if (login_result['reject_remark']) {
                // console.log(login_result['reject_remark']);
                this.reject_remark = login_result['reject_remark'];
              }

              var user_survey_data=null;
              user_survey_data = login_result["user_survey_data"];

              if (this.survey_submitted == 1) {
                this.surveytemplate["pageNextText"] = "Next";
              }
              this.survey = new Model(this.surveytemplate);
              if (this.survey_response && this.survey_response != "") {
                this.survey.data = JSON.parse(this.survey_response);
              }
              if (Object.keys(user_survey_data).length > 0) 
              {
                this.approval_status=user_survey_data['approval_status'];
                console.log("status",this.approval_status);
                
                if (user_survey_data['approval_status']=='Approved') {
                  this.show_msg="तुमचा आधार तपशील मंजूर झाला आहे";
                  this.show_survey=false;
                }
                else if (user_survey_data['approval_status']=='Rejected') {
                  this.show_survey=true;
                }
                else if (user_survey_data['approval_status']=='Pending' && user_survey_data['aadhar_match']==1) {
                  // this.show_survey=false;
                  // this.show_msg="Your Aadhar Details Has been Approved";
                  // this.show_msg="तुमचा आधार तपशील मंजूर झाला आहे";
                  // this.survey.mode = "display";
                  this.show_survey=true;
                }
                else if (this.approval_status=='Pending' && user_survey_data['aadhar_match']==0) {
                  // this.survey.mode = "display";
                  this.show_survey=true;
                }
                 
              }
              else if (Object.keys(user_survey_data).length == 0) {
                this.show_survey=true;
              }
              
              this.survey.applyTheme(themeJson);
              this.survey.onUploadFiles.add((survey, options) => {
            
            var fileUp = options.files[0];
            var filetype = options.files[0].type;
            var acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
            if (!acceptedImageTypes.includes(filetype)) 
            {
                console.log("Only image files are allowed");
                options.callback("error", "Only image files (JPEG, PNG, GIF) are accepted.");
                return;
            }
            var filepath = options.name + '_' + options.files[0].name;
            var file = fileUp;
            var data = {
                filePath: filepath,
                contentType: filetype
            };
            var userid = this.$route.params.username+'_Aadhar';
            //upload to aws
            //let promise = apis.upload_to_AWS(data);
            let promise = lambda.getSignedURL(userid, filepath, filetype);
            promise
                .then((response) => {
                    let signedUrl = response;
                    var upldoptions = {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': options.files[0].type
                        }
                    };
                    return axios.put(signedUrl, file, upldoptions);
                })
                .then((res) => {
                    console.log('res', res);
                    options.callback(
                        'success',
                        options.files.map((file) => {
                            return {
                                file: file,
                                content:"https://s3.ap-south-1.amazonaws.com/sgbimages/" + userid +"/" +filepath,
                            };
                        })
                    );
                });
        });
              this.survey.onComplete.add((survey) => {
                this.result = JSON.stringify(survey.data);
                let jwtToken = this.jwtToken;
                let details = {
                  sgb_board_id: this.sgb_board_id,
                  emp_code: this.employee_code,
                  survey_id: this.survey_id,
                  survey_response: this.result,
                  type: "submit",
                };
                var header = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwtToken}`,
                  },
                };
                this.loading = true;
                var promise = apis.save_guard_survey_new(details, header);
                promise.then((response) => {
                  this.loading = false;
                  if (response.data.status == true) {
                    Toast.fire({
                      icon: "success",
                      title: response.data.message,
                    });
                    // this.$router.push('/');
                  } else {
                    Toast.fire({
                      icon: "error",
                      title: response.data.message,
                    });
                  }
                });
              });
              // this.survey.onPartialSend.add((survey) => {
              //   this.result = JSON.stringify(survey.data);
              //   let jwtToken = this.jwtToken;
              //   let details = {
              //     "sgb_board_id": this.sgb_board_id,
              //     "emp_code": this.employee_code,
              //     "survey_id": this.survey_id,
              //     "survey_response": this.result,
              //     "type": 'save'
              //   }
              //   var header = {
              //     headers: {
              //       "Content-Type": "application/json",
              //       'Authorization': `Bearer ${jwtToken}`
              //     }
              //   };
              //   this.loading = true;
              //   var promise = apis.save_guard_adhar_survey(details, header);
              //   promise.then((response) => {
              //     this.loading = false;
              //   });
              // });
              this.survey.onValueChanged.add((sender, options) => {});
              // if (this.survey_submitted == 1) {
              //   this.survey.nextButtonText = "Next";
              //   this.survey.mode = "display";
              // }
            } else {
              Toast.fire({
                icon: "warning",
                title: "record Not Found",
              });
            }
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.message,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("API Error:", error);
        });
      //Login api end
    },
  },
  async mounted() {
    await this.Log_in();
  },
};
</script>
<style>
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 91vh;
}
.container {
  flex: 1;
}
.btn-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.info {
  background-color: #005487;
  width: 130px;
  height: 40px;
  border-radius: 5px;
  border: 0;
  color: #fff;
  font-size: 15px;
  margin-left: 10px;
  cursor: pointer;
}
.info2 {
  background-color: #05a32d;
  width: 130px;
  height: 40px;
  border-radius: 5px;
  border: 0;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}
.card:last-child {
  margin-top: 5%;
}
.error {
  color: red;
}
</style>
