import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
//import sgb_survey from '../views/Survey.vue'
//import Login from '../views/Login.vue'
import new_sgb_survey from '../views/new_Survey.vue'
import Aadhar_survey from '@/views/aadhar_survey.vue'
const routes = [
  // {
  //   path: '/:sgb_board_id/:emp_code',
  //   name: 'sgb_survey',
  //   component: sgb_survey
  // },
  // {
  //   path: '/new_sgb_survey',
  //   name: 'new_sgb_survey',
  //   component: new_sgb_survey
  // },
  // {
  //   path: '/',
  //   name: 'Login',
  //   component: Login
  // },
  {
    path: '/:username',
    name: 'new_sgb_survey',
    component: new_sgb_survey
  },
  {
    path: '/aadhar_survey/:username',
    name: 'aadhar_survey',
    component: Aadhar_survey
  }
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
