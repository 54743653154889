import 'bootstrap/dist/css/bootstrap.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import FilePreviewComponent from "./FilePreviewComponent.vue";
import 'survey-core/defaultV2.min.css';
import { surveyPlugin } from 'survey-vue3-ui'



//npm install @fortawesome/vue-fontawesome@latest @fortawesome/fontawesome-svg-core @fortawesome/free-solid-svg-icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
// Add the icons to the library
library.add(fas);

//createApp(App).use(router).use(surveyPlugin).use(VueSweetalert2).mount('#app')
const app = createApp(App);
app.use(router);
app.use(surveyPlugin);
app.use(VueSweetalert2);
// Register the FontAwesomeIcon component globally
app.component('font-awesome-icon', FontAwesomeIcon);
app.component("sv-file-preview", FilePreviewComponent);
app.mount('#app');
'import bootstrap/dist/js/bootstrap.js'
