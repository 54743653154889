//  var url    = "http://3.109.162.244/";  //UAT
var url   =   "https://erpapi.sgbregistration.in/" //live
var uat_url    = "http://3.109.162.244/";
// var jwtToken = localStorage.getItem('jwtToken');
import axios from "axios";

let headers = {
    headers: {
      "Content-Type": "application/json",
      
    }
  };

export default {
    
    login(data)
    {
        return axios.post(url + "v1/Login/Login_now/guard_login_survey", JSON.stringify(data) , headers)
    },
    save_guard_survey(data,header)
    {
        return axios.post(url + "v1/Masters/Medical_Test/save_guard_survey", JSON.stringify(data),  header)
    },
    save_guard_survey_new(data,header)
    {
        return axios.post(url + "v1/Masters/Medical_Test/save_guard_survey_new", JSON.stringify(data),  header)
    },
    save_guard_adhar_survey(data,header)
    {
        return axios.post(url + "v1/Masters/Medical_Test/save_guard_adhar_survey", JSON.stringify(data),  header)
    },
    uat_login(data)
    {
        return axios.post(url + "v1/Login/Login_now/guard_login_survey", JSON.stringify(data) , headers)
    }
   


}